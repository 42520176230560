import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import SolutionsPage from '../components/SolutionsPage';

import Link from '../../../core/Link';

import heritageLogo from '../../../../images/logos/Heritage-house-transparent-crop.svg';
import threeFallsLogo from '../../../../images/logos/three-falls-cove.png';
import eightDyer from '../../../../images/logos/8dyer-transparent.svg';
import trailsideLogo from '../../../../images/logos/Trailside-transparent-crop.svg';

import hero from './images/hero.png';
import support from './images/support_panel.png';
import payment from './images/payment_panel.png';
import simplicity from './images/simplicity_panel.png';
import booking_engine_home from './images/booking_engine_home.png';
import guest_experience from './images/guest_experience_panel.png';
import channel_manager_diagram from './images/channel_mgr_diagram.svg';

import CenteredCardWithImage from '../../../ui-library/Hero/CenteredCardWithImage';
import LogoScroll from '../../../ui-library/LogoScroll';
import SimpleFeatureGrid from '../../../ui-library/FeatureSections';
import TwoColumnCta from '../../../ui-library/TwoColumnCta';
import SimpleCTASection from '../../../ui-library/CTASections';
import Testimonial from '../../../ui-library/Testimonials/Testimonial';
import HeaderSection from '../../../ui-library/HeaderSection';
import SimpleCenteredTestimonial from '../../../ui-library/Testimonials/SimpleCenteredTestimonial';
import SimpleThreeColumn from '../../../ui-library/FeatureSections/SimpleThreeColumn';

const logos = [
  // {
  //   src: blisswoodLogo,
  // },
  {
    src: heritageLogo,
  },
  {
    src: threeFallsLogo,
  },
  {
    src: eightDyer,
  },
  {
    src: trailsideLogo,
  },
];

export class HotelsPage extends Component {
  render() {
    return (
      <SolutionsPage>
        <Helmet title="Reservation Management Software for Small Hotels – ThinkReservations">
          <meta
            name="description"
            content="ThinkReservations offers small hotel reservation software and other solutions to give you an edge over bigger competitors. Click here to learn more."
          />
          <script
            type="application/ld+json"
            children={JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Service',
              serviceType: 'Hotel Reservation Management Software Solutions',
              category:
                'Reservation Management Software for Small and Botique Hotels',
              provider: {
                '@type': 'Organization',
                name: 'ThinkReservations',
              },
              areaServed: {
                '@type': 'Country',
                name: 'USA',
              },
            })}
          />
        </Helmet>
        <CenteredCardWithImage
          Img={() => (
            <img
              className="h-full w-full object-cover"
              src={hero}
              alt="A customer arriving at the front desk."
            />
          )}
          heading="Reservation Management Software For Small & Boutique Hotels"
          subheading="Your guests expect white-glove service and your undivided attention. This is why choosing our boutique hotel management software solution can be such a differentiator."
        />
        <SimpleFeatureGrid />
        <TwoColumnCta
          headline="Simplicity + Efficiency for Your Staff"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  ThinkReservations is the perfect solution for hotels that want
                  to streamline their operations without sacrificing quality or
                  service. You and your team didn't get into the hospitality
                  industry to sit behind a computer. We get it.{' '}
                </p>
                <p>
                  With our powerful all-in-one{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    property management software
                  </Link>
                  , you'll get an intuitive, easy-to-use solution with the
                  automation you need to streamline your day-to-day tasks. Gain
                  the freedom to concentrate on delivering the unique,
                  personalized experience your guests want.
                </p>{' '}
                And we'll help make sure your team is trained and comfortable
                from day one. You won't need to set aside days or weeks to get
                everyone up and running.
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={simplicity}
              alt="A customer arriving at the front desk."
            />
          )}
        />
        <div className="my-32"></div>
        <SimpleCenteredTestimonial
          quote={`The entire process, from speaking with a sales representative to installation and training to going live, was absolutely seamless on ThinkReservation's end. Would that all other digital transitions go as smoothly! Thank you very much!`}
          author="David Johnson"
          position="Tradewinds on the Bay"
        />
        <div className="my-32"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="The Guest Experience Starts Here"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  With ThinkReservations, your guests can easily book direct
                  right on your website with our{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    mobile-responsive booking engine.
                  </Link>{' '}
                  Whether on a smartphone, laptop, tablet, or desktop, they'll
                  enjoy a customized booking experience that's fast, easy, and
                  consistent with your brand.
                </p>{' '}
                <p>
                  Increase revenue year over year with the ability to upsell
                  guests on rooms, packages, and amenities as they're making
                  their reservations or anytime after.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={guest_experience}
              alt="A screenshot of the booking engine showing a room."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCTASection
          Img={() => (
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src={booking_engine_home}
              alt="App screenshot showing the landing page for the booking engine."
            />
          )}
        />
        <div className="my-24"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="No More Double-Bookings"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Say goodbye to the days of worrying about overbooking. With
                  our{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/channel-manager"
                  >
                    channel manager
                  </Link>
                  , you can rest assured that your third-party OTA listings are
                  always accurate. We send real-time two-way availability and
                  pricing to sites like Booking.com, Expedia, Airbnb,
                  HotelTonight, and Tripadvisor so you never worry about a
                  double-booking again.
                </p>{' '}
                We also offer the flexibility you need to be in control of your
                OTA relationships. Only share what you want, when you want, so
                you can optimize your marketing while maximizing your revenue.
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-slate-50 p-8">
              <img
                className="w-full"
                src={channel_manager_diagram}
                alt="A diagram outlining the several connections available in the channel manager."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          headline="Make Data-Driven Business Decisions"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Your boutique hotel has a lot of moving parts. Keep track of
                  it all with our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/articles/reports"
                  >
                    robust reporting
                  </Link>{' '}
                  features. Get a quick overview of your hotel's performance or
                  dive deep into the data to find areas for improvement. Keep
                  everyone on the same page and work together to drive results.
                </p>
                <p>
                  You'll especially love our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/webinars/reports"
                  >
                    Booking pace report
                  </Link>{' '}
                  . It tells you how many rooms are booked for any given period
                  and compare with another timeframe. Using your historical
                  booking data, you can see patterns and make predictions for
                  the future. This report is essential for forecasting
                  occupancy, making adjustments on the fly, and planning your
                  marketing strategy… and helping you make more revenue!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="John T"
                position="Dockside Inn"
                quote="I am in a market that celebrates enormously high occupancy (95-99% in season). I didn't think a reservation software could play a pivotal role in advanced bookings. My advanced bookings are far beyond my expectations and the only change I made was ThinkReservations. Yield management is also simple to set up and an easy way to increase profit with very little work. The program does it all."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Integrate with the Payment Processor of Your Choice"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                We know that you need to be able to take payments in the way
                that's best for your business. That's why we offer free
                integrations with a variety of{' '}
                <Link
                  className="text-think-blue"
                  to="/products/payment-processing"
                >
                  payment processors
                </Link>{' '}
                . Our{' '}
                <Link className="text-think-blue" to="/articles/pci-compliance">
                  PCI-compliant
                </Link>{' '}
                software connects with your processor for fast, reliable, and
                secure deposits and payments. You can also safely store guest
                credit card details for collecting balances at check-in or
                charging for extras during their stay. You even have the option
                to keep it on file for future reservations!
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={payment}
              alt="A woman entering credit card info on a laptop."
            />
          )}
        />
        <div className="my-24"></div>
        <HeaderSection
          title="Connect with Your Favorite Third-Party Tools"
          copy={null}
          children={
            <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
              Continue using the tools you love along with ThinkReservations. We
              offer{' '}
              <Link className="text-think-blue" to="/products/integrations">
                integrations
              </Link>{' '}
              with a variety of third-party software to make it even easier to
              run your business. With options like OTAs, point-of-sale, and
              guest communication, you’ll have everything you need seamlessly
              connected.
            </p>
          }
        />
        <div className="my-24"></div>
        <LogoScroll logos={logos} />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Bring Your History With You"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Speaking of data, we know one of the scariest parts about
                  moving to a new PMS is losing your existing reservation and
                  guest details. We've thought of that! That's why when you
                  upgrade to ThinkReservations, we'll import your historic data
                  for you. For free.
                </p>
                <p>
                  Our team has successfully handled 1000+ data imports from a
                  wide variety of property management systems, so we’re
                  confident we can do the same for you.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Hermann"
                position="MO - Luxury Hotel"
                quote="ThinkReservations is a booking revolution This system will make it so easy to keep your business running with minimal effort. All the day to day operations of managing your reservations simply takes care of itself."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <h1 className="max-w-7xl mx-auto px-4 mt-1 block text-4xl text-center tracking-tight font-extrabold sm:text-5xl">
          Service + Support With You in Mind
        </h1>
        <SimpleThreeColumn />
        <TwoColumnCta
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  With a dedicated team of Customer Success specialists (many
                  with real-world hospitality experience), we are here to treat
                  you the way you treat your guests. We want you to feel
                  confident that if you need anything, our team is here for you.
                </p>
                <p>
                  We offer comprehensive onboarding, account setup, and training
                  when you become a client. We have an online self-service
                  support center with tons of articles and videos for when
                  questions pop up. And our US-based customer support is
                  available via email, phone, and chat, all included in your
                  subscription. We’ve even got emergency phone support
                  after-hours just in case.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={support}
              alt="A happy customer talking with support."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCenteredTestimonial
          quote={`The entire process, from speaking with a sales representative to installation and training to going live, was absolutely seamless on ThinkReservation's end. Would that all other digital transitions go as smoothly! Thank you very much!`}
          author="David Johnson"
          position="Tradewinds on the Bay"
        />

        <HeaderSection
          title="Supporting Independent Lodging Properties for Over 10 Years"
          copy={null}
          subtitle={null}
          children={
            <>
              <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
                <p>
                  We've been working with boutique hotels for over 10 years and
                  we're dedicated to helping you succeed. We understand the
                  unique challenges and opportunities you face as an independent
                  hotelier and we’re here to help you meet the evolving needs of
                  today's travelers.{' '}
                  <Link className="text-think-blue" to="/products/integrations">
                    Learn more about our team.
                  </Link>{' '}
                </p>
                <p>
                  Choose a property management solution that will help you grow
                  your business while delivering the exceptional experience your
                  guests have come to expect.
                </p>
              </p>
              <Link to="/request-a-demo">
                <button
                  className="block max-w-xl mx-auto w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                  type="submit"
                >
                  Request a demo today
                </button>
              </Link>
            </>
          }
        />
        <div className="my-24"></div>
      </SolutionsPage>
    );
  }
}

export default HotelsPage;
